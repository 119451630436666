/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: #2B2D42;  }

p, h1, h2, h3{
    color: white;
}

.formulario{
    width: 70%;
    margin: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.formularioDatos{
    width: 50%;
    margin: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.drawingcard{
    padding: 0 !important;
}

.mat-card-image{
    display: inline-block !important;
    margin: 0 !important;
}
.mat-form-field{
 color: white;
}
.formularioDatos mat-form-field {
    width: 100%;
  }
.formulario mat-form-field {
    width: 100%;
  }
form{
    width: 100%;
}
.spinner {
    margin: 0 auto;
}